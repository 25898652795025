<template>
  <VueFinalModal :focus-trap="true" name="captcha" :click-to-close="true" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen" @closed="onClose">
    <div class="modal-captcha">
      <p class="modal-captcha__header">{{ $t("modal.captcha.title") }}</p>
      <div class="modal-captcha__content">
        <button type="button" class="modal-captcha__card" v-for="i in 9" :key="`captcha-${i}`" @click="onRotate(i - 1)">
          <img
            v-if="captcha.blocks[i - 1]"
            :src="`data:image/png;base64,${encodeURI(captcha.blocks[i - 1])}`"
            class="modal-captcha__image"
            :style="{ transform: `rotate(${rotates[i - 1] * 90}deg)` }"
            alt=""
          />
        </button>
      </div>
      <p v-if="message.length" id="message" style="" class="modal-captcha__message modal-captcha__message--danger">{{ message }}</p>
      <div class="modal-captcha__btns">
        <button type="button" class="modal-captcha__validate" id="validate" @click="validateCaptcha">{{ $t("modal.captcha.submit") }}</button>
        <button v-if="!depositId" type="button" class="modal-captcha__validate modal-captcha__reload" @click="updateCaptcha">
          <IconReload class="modal-captcha__reload-icon" />
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { $vfm, VueFinalModal } from "vue-final-modal";
import IconReload from "@/assets/svg/reload.svg?component";
const emit = defineEmits(["verify"]);

const { $axios } = useNuxtApp();

const captcha = ref({
  id: null,
  blocks: [],
});

const message = ref("");
const depositId = ref();

const rotates = ref(Array(9).fill(0));

function getSolution() {
  if (depositId.value)
    return Object.values(rotates.value)
      .map((i) => i % 4)
      .join("");
  return parseInt(
    Object.values(rotates.value)
      .map((i) => i % 4)
      .join(""),
    4
  );
}

function onRotate(index) {
  rotates.value[index] += 1;
}

function updateCaptcha() {
  message.value = "";
  $axios.$get("user/captcha/init").then(({ response }) => {
    if (response) {
      if (response.notify) {
        message.value = response.notify.content;
      } else if (response.id) {
        Object.assign(captcha.value, response);
      }
    }
  });
}
async function validateCaptcha() {
  if (depositId.value) {
    return callback.value(getSolution());
  }
  message.value = "";
  $axios
    .$post("user/captcha/check", {
      id: captcha.value.id,
      solution: getSolution(),
    })
    .then(({ response }) => {
      if (response) {
        if (response.notify) {
          message.value = response.notify.content;
        } else if (response.message) {
          message.value = response.message;
        }

        if (response.status === "bad") {
          updateCaptcha();
        } else if (response.status === "good") {
          callback.value(captcha.value.id);
          $vfm.hide("captcha");
        }
      }
    });
}

const callback = ref(() => {});

function onBeforeOpen(e) {
  depositId.value = e?.ref?.params?.value?.deposit_id;
  if (!depositId.value) {
    try {
      updateCaptcha();
    } catch (e) {}
  } else {
    Object.assign(captcha.value, {
      id: depositId.value,
      blocks: e?.ref?.params?.value?.images,
    });
  }
  if (e?.ref?.params?.value?.verify) callback.value = e?.ref?.params?.value?.verify;
}
function onClose() {
  message.value = "";
  rotates.value = Array(9).fill(0);
  Object.assign(captcha.value, {
    id: null,
    blocks: [],
  });
}
</script>

<style lang="scss">
.modal-captcha {
  padding: 10px;
  background-color: #fff;
  touch-action: manipulation;
  user-select: none;
  overflow: hidden;

  max-width: 330px;

  // repeat

  &__btns {
    display: flex;

    button + button {
      margin-left: 5px;
    }
  }

  &__header {
    color: #fff;
    padding: 16px;
    font-size: 18px;
    background: #0984e3;
    text-align: center;
  }
  &__content {
    display: grid;
    margin: 0 auto;
    padding: 5px 0;
    grid-gap: 5px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: center;

    & > * {
      aspect-ratio: 1/1;
    }
  }
  &__message {
    &--danger {
      color: #f44336;
    }
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__validate {
    width: 100%;
    padding: 16px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #0984e3;
    transition: all 0.4s;
    border: none;
  }
  &__card {
    background: rgba(128, 128, 128, 0.35);
    display: flex;
  }
  &__image {
    width: 100%;
    height: 100%;
    transition: transform 0.15s ease-in-out;
  }

  &__reload {
    padding: 0;
    width: 53px;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    &-icon {
      color: #ffffff;
      width: 20px;
      height: auto;
    }
  }
}
</style>
